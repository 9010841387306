<template>
  <div class="filters">
    <b-form-group label="Select Grade"
                  label-for="gradeDropdown"
    >
      <b-form-select
        v-model="selectionForm.grade"
        :options="grades"
        placeholder="Select Grade"
        @change="getSubject"
      />
    </b-form-group>

    <b-form-group label="Select Subject"
                  label-for="gradeDropdown"
    >
      <b-form-select
        v-model="selectionForm.subject"
        placeholder="Select subjects"
        :options="subjects.map((sub) => ({ value: sub.id, text: sub.name }))"
      />
    </b-form-group>
    <b-row class="d-flex justify-content-between">
      <b-col md="6">
        <b-form-group label="Search Domain"
                      label-for="domainSearch"
        >
          <b-form-input
            id="domainSearch"
            v-model="searchQuery"
            placeholder="Type to search domains..."
          />
        </b-form-group>
      </b-col>

      <b-col md="6">
        <b-form-group label="Select View"
                      label-for="viewTypeDropdown"
        >
          <b-form-select
            v-model="viewType"
            :options="[
              { value: 'detail', text: 'Detail View' },
              { value: 'compact', text: 'Compact View' },
            ]"
            placeholder="Select View Type"
          />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="domain-buttons mb-1">
      <template v-if="viewType == 'compact'">
        <b-button
          v-for="domain in filteredDomains"
          :key="domain.id"
          v-b-tooltip.hover="domain.name"
          variant="outline-dark"
          class="mb-1 ml-1 me-1"
          :class="{ 'active btn-success': domain.id === selectionForm.domain }"
          @click="selectDomain(domain)"
        >
          {{ domainNumber(domain.code) }}
        </b-button>
      </template>
      <template v-else>
        <TreeView :key="selectionForm.subject"
                  :domains="filteredDomains"
                  :default-standard="props.event?.standard_id || 0"
                  @change="(domain, standard) => selectDomain(domain, standard)"
        />
      </template>
    </div>
  </div>
</template>

<script setup>
import axios from 'axios'
import {
  BFormGroup, BFormSelect, BButton, BFormInput, BRow, BCol,
} from 'bootstrap-vue'
import {
  ref, onMounted, reactive, computed,
} from 'vue'
// import getGrades from '@/views/AiDemo/selected-grade'
import TreeView from './TreeView.vue'

const props = defineProps({
  event: {
    type: Object,
    default: null,
  },
})

const subjectEndPoint = '/api/v1/ai-demo/get-subjects'
const reaStandardGradeEndpoint = '/api/v1/rea/rea-standards'

const viewType = ref('detail')
const searchQuery = ref('')

const emits = defineEmits(['onDomainChange'])
const DEFAULT_GRADE = process.env.VUE_APP_REA_DEFAULT_SUBJECT ?? 49

const selectionForm = reactive({
  grade: props.event?.grade_id || DEFAULT_GRADE,
  subject: props.event?.subject_id || null,
  domain: props.event?.domain_id || null,
})

const grades = ref([
  {
    value: null,
    text: 'Select Grade',
  },
])

const subjects = ref([
  {
    value: null,
    name: 'Select Subjects',
  },
])

const domains = computed(
  () => subjects.value.find(sub => sub.id === selectionForm.subject)?.domains
    || [],
)

const filteredDomains = computed(() => {
  if (!searchQuery.value) {
    return domains.value
  }
  const query = searchQuery.value.toLowerCase()
  return domains.value.filter(domain => domain.name.toLowerCase().includes(query))
})

const domainNumber = computed(() => code => {
  // Match the number after the last two dots
  const match = code.match(/(\d+\.\d+(\.\d+)?)$/)
  return match ? match[0] : code // Remove the first dot
})
const getGrades = async () => {
  const response = await axios.get(`${reaStandardGradeEndpoint}`)
  grades.value = response.data.data.map(grade => ({
    text: grade.name,
    value: grade.id,
  }))
  return response
}
const getSubject = async () => {
  const response = await axios.post(`${subjectEndPoint}/${selectionForm.grade}`)
  subjects.value = response.data
  if (!props.event) {
    selectionForm.subject = response.data[0]?.id || null
  }
}

const selectDomain = (domain, standard) => {
  emits('onStandardChange', standard)
  if (selectionForm.domain === domain.id) {
    return
  }
  selectionForm.domain = domain.id
  emits('onDomainChange', domain)
}

onMounted(async () => {
  await getGrades()
  await getSubject()
  if (props.event) {
    const domain = subjects.value.find(sub => sub.id === props.event.subject_id).domains.find(sub => sub.id === props.event.domain_id)
    const standard = domain.standards.find(sub => sub.id === props.event.standard_id)
    emits('onStandardChange', standard)
    emits('onDomainChange', domain)
  }
})
</script>
