<template>
  <div>
    <BotHeaderArea />

    <BOverlay :key="pageKey"
              :show="isFetchingAssignment"
    >
      <div
        v-if="!isFetchingAssignment"
        class="vh-100 d-flex align-items-center"
      >
        <b-row class="w-100 h-100 p-1">
          <b-col md="3"
                 class="border border-warning p-1 domain-filter "
          >
            <GradeCourseFilter
              :event="reaEvent"
              @onDomainChange="(v) => (state.selectedDomain = v.id, activeDomain = v)"
              @onStandardChange="v => state.selectedStandard = v.id"
            />
          </b-col>
          <b-col md="9"
                 class="border border p-1"
          >
            <div v-if="!state.selectedDomain"
                 class="h-100 d-flex align-items-center justify-content-center"
            >
              <h4>Please select a domain first</h4>
            </div>

            <div v-else
                 class="mt-1"
            >
              <StandardFilter
                v-if="state.selectedDomain"
                :key="state.selectedDomain"
                :domain-id="state.selectedDomain"
                :domain="activeDomain"
                :rea-standard="reaEvent?.standard_id || 0"
                :default-standard="state.selectedStandard"
                class="mb-1"
                @onStandardChange="v => { state.reactiveStandard = v ; getChatHistory() }"
                @onMainPointChange="(v) => (state.selectedMainPoint = v)"
              />
              <b-tabs
                :key="state.reactiveStandard"
                nav-class="custom-tabs"
                pills
              >
                <b-tab title="AI Assistant"
                       active
                       @click="getChatHistory"
                >
                  <DemoChat
                    :messages="[...state.messages].reverse()"
                    :text-streaming="textStreaming"
                    :is-bot-typing="state.isBotTyping || isSavingMessage"
                    @onMessage="handleOnMessage"
                  />
                </b-tab>

                <b-tab title="Essay">
                  <EssayMode />
                </b-tab>
                <b-tab title="Assessment"
                       lazy
                >
                  <ContextTab :form="contextForm"
                              :selected-main-point="state.selectedMainPoint"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-col>
        </b-row>
      </div>
      <gec-dragger
        v-show="chatOpened"
        :id="`draggable-chat-bot-${state.reactiveStandard}`"
        :height="200"
        :width="300"
        draggable-section="chatbot-body"
      >
        <section
          v-show="chatOpened"
          data-v-5e8ea5c2=""
          class="chat-app-window w-100 h-100 mb-2"
          style="background: transparent;"
        >
          <div data-v-5e8ea5c2=""
               class="active-chat w-100 h-100"
          >
            <ChatBot
              voice-type="open-ai"
              :is-saving-message="isSavingMessage"
              :selected-main-point="contextInfo.mainPoints"
              :history="history"
              @saveStudentChatHistory="saveStudentChatHistory"
            />
          </div>
        </section>
      </gec-dragger>
    </BOverlay>
  </div>

</template>
<script setup>
import {
  BRow, BCol, BOverlay, BTab, BTabs,
} from 'bootstrap-vue'
import {
  reactive, ref, getCurrentInstance, onMounted, computed, watch,
} from 'vue'
import { Converter } from 'showdown'
import GecDragger from '@/layouts/components/GECDragger.vue'
import useJwt from '@/auth/jwt/useJwt'
import GradeCourseFilter from './components/GradeStandardFilter.vue'
import StandardFilter from './components/StandardFilter.vue'
import DemoChat from './components/DemoChat.vue'
import ChatBot from './components/ChatBody.vue'
import BotHeaderArea from './components/BotHeaderArea.vue'
import ContextTab from './contexts/index.vue'
import EssayMode from './essay-mode/index.vue'
import { streamText } from '../demobot/helpers/streamAudio'

const chatOpened = ref(true)
const root = getCurrentInstance().proxy.$root
const contextForm = reactive({
  customText: '',
  selectedPrompt: null,
  contextTab: 0,
})

const state = reactive({
  selectedMainPoint: [],
  selectedDomain: null,
  selectedStandard: null,
  messages: [],
  isBotTyping: false,
  reactiveStandard: null,
})

const store = root.$store

watch(() => state.reactiveStandard, newStandard => {
  store.dispatch('reaStore/setActiveStandard', newStandard)
})

watch(() => state.selectedMainPoint, points => {
  store.dispatch('reaStore/setActiveMainPoints', points)
})

const isSavingMessage = ref(false)
const isFetchingAssignment = ref(false)
const activeDomain = ref(null)
const reaEvent = ref(null)
const history = ref([])
const textStreaming = ref('')
const bufferTopics = computed(() => `rea_history${state.reactiveStandard}`)

const pageKey = computed(() => reaEvent.value?.event_id || 0)

const contextInfo = computed(() => {
  const currentMainPoint = [...state.selectedMainPoint]
  if (contextForm.contextTab === 0 && contextForm.customText) {
    currentMainPoint.push(contextForm.customText)
  }

  return {
    selectedPrompt: contextForm.contextTab === 1 ? contextForm.selectedPrompt : null,
    mainPoints: currentMainPoint,
  }
})

const saveStudentChatHistory = ({ cacheKey = null, msg, assistantMessage = null }) => {
  if (root.AUTH_USER() && state.reactiveStandard) {
    isSavingMessage.value = true
    const noSave = !root.$store.state.reaStore.canSaveConversationBuffer
    useJwt.saveStudentBotChatHistory({
      cacheKey,
      topics: bufferTopics.value,
      input: msg,
      noSave,
      standardId: state.reactiveStandard,
      response: assistantMessage,
      mainPoints: state.selectedMainPoint,
    }).then(response => {
      if (!noSave) {
        history.value = response.data.data.new_history
      }
    }).catch(error => {
      root.showErrorMessage(error)
    }).finally(() => {
      isSavingMessage.value = false
    })
  }
}

const handleOnMessage = async msg => {
  state.messages.push({
    content: msg,
    role: 'user',
  })

  const params = {
    input: msg,
    prompt: '-',
    main_points: contextInfo.value.mainPoints,
    history: state.messages,
    language: null,
    voice: 'onyx',
    modeId: store.state.reaStore.selectedReaPromptId || null,
  }
  if (contextInfo.value.selectedPrompt) {
    params.modeId = contextInfo.value.selectedPrompt
  }
  state.isBotTyping = true
  try {
    const textResponse = await streamText(params, txt => {
      textStreaming.value += txt
    })
    const convertor = new Converter()
    state.messages.push({
      content: convertor.makeHtml(textResponse.text),
      role: 'assistant',
    })
    saveStudentChatHistory({
      msg,
      assistantMessage: textResponse.text,
    })
    textStreaming.value = ''
  } catch (error) {
    root.showErrorMessage(error)
  } finally {
    state.isBotTyping = false
  }
}

const getChatHistory = () => {
  if (root.AUTH_USER() && state.reactiveStandard) {
    useJwt.getReaLabChatHistory({
      params: {
        key: bufferTopics.value,
      },
    }).then(response => {
      history.value = response.data.data.history
      state.messages = history.value
    })
  }
}

onMounted(() => {
  if (root.$route.query.event_id) {
    isFetchingAssignment.value = true
    useJwt.showReaAssignment(root.$route.query.event_id).then(async response => {
      const { data } = response.data
      reaEvent.value = data
    }).finally(() => {
      isFetchingAssignment.value = false
    })
  } else if (root.$route.query.standard_id) {
    isFetchingAssignment.value = true
    useJwt.showReaBasedOnStandard(root.$route.query.standard_id).then(async response => {
      const { data } = response.data
      reaEvent.value = data
    }).finally(() => {
      isFetchingAssignment.value = false
    })
  }
  getChatHistory()
})
</script>
<style lang="css">
.floating-bot-icon{
  z-index: 1031;
  background-color: rgb(253, 178, 39);
  padding:10px;
  border-radius:50%;
  cursor:pointer;
  transition: 0.3s;
  position: fixed;
  bottom: 5%;
  right: 5%;
}

.domain-filter {
  max-height: 100vh;
  overflow: auto;
}
</style>
