<template>
  <div class="essay-container">
    <div class="row">
      <div class="col-6">
        <div class="panel">
          <div class="panel-title">
            <span class="title-text w-100">
              User
              <slot />
            </span>
            <div class="title-accent blue-accent" />
          </div>
          <div class="essay-content" v-html="processConversation(props.userMessage)" />
        </div>
      </div>

      <div class="col-6">
        <div class="panel">
          <div class="panel-title">
            <span class="title-text">Response</span>
            <div class="title-accent green-accent" />
          </div>
          <div class="response-content"
               v-html="getConvertedText(props.assistantMessage)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { Converter } from 'showdown'

const props = defineProps({
  userMessage: {
    type: String,
    default: '',
  },
  assistantMessage: {
    type: String,
    default: '',
  },
})

const processConversation = text => {
  try {
    // Try to parse the text as JSON
    const messages = JSON.parse(text)
    // Check if it's an array
    if (!Array.isArray(messages)) {
      return false
    }

    // Check if each item has the required structure
    // eslint-disable-next-line no-prototype-builtins
    const isValidFormat = messages.every(message => message.hasOwnProperty('content')
            // eslint-disable-next-line no-prototype-builtins
            && message.hasOwnProperty('role')
            && (message.role === 'user' || message.role === 'assistant'))

    if (!isValidFormat) {
      return text
    }

    // If valid, process the conversation
    const container = document.createElement('div')

    messages.reverse().forEach(message => {
      const messageDiv = document.createElement('div')
      const roleLabel = message.role === 'user' ? 'User: ' : 'Assistant: '
      messageDiv.innerHTML = `<strong>${roleLabel}</strong>${message.content}`
      container.appendChild(messageDiv)
    })

    return container.innerHTML
  } catch (error) {
    // If JSON.parse fails, return false
    return text
  }
}

const getConvertedText = text => {
  const convertor = new Converter()
  return convertor.makeHtml(text)
}

// You can add your reactive variables and functions here
</script>

  <style scoped>
  .essay-container {
    margin: 0 auto;
  }

  .row {
    display: flex;
    gap: 20px;
  }

  .col-6 {
    flex: 1;
  }

  .panel {
    border: 1px solid #e0e4e8;
    border-radius: 8px;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.04);
  }

  .panel:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-color: #d0d4d8;
  }

  .panel-title {
    padding: 12px 16px;
    font-weight: 600;
    border-bottom: 1px solid #e0e4e8;
    position: relative;
    display: flex;
    align-items: center;
    color: #2c3e50;
  }

  .title-text {
    position: relative;
    z-index: 1;
  }

  .title-accent {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 3px;
    width: 40px;
    border-radius: 2px;
    margin: 0 16px;
  }

  .blue-accent {
    background: #3498db;
  }

  .green-accent {
    background: #2ecc71;
  }

  .essay-content, .response-content {
    padding: 16px;
    line-height: 1.6;
    color: #4a5568;
    font-size: 0.95rem;
  }

  /* Hover effects */
  .panel:hover .title-accent {
    width: 60px;
    transition: width 0.3s ease;
  }

  /* Responsive design */
  @media (max-width: 768px) {
    .row {
      flex-direction: column;
    }

    .col-6 {
      width: 100%;
    }

    .panel {
      margin-bottom: 16px;
    }

    .panel:last-child {
      margin-bottom: 0;
    }
  }

  /* Adding a subtle background pattern */
  .panel {
    background-image:
      linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)),
      repeating-linear-gradient(
        45deg,
        #f8fafc 0,
        #f8fafc 10px,
        #ffffff 10px,
        #ffffff 20px
      );
  }

  /* Smooth transitions */
  .panel, .panel-title, .title-accent {
    transition: all 0.3s ease;
  }
  </style>
