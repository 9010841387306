<template>
  <div class="essay-writer">
    <div class="essay-header mb-3">
      <h4 class="essay-title d-flex align-items-center">
        <feather-icon icon="Edit3"
                      class="mr-2"
                      size="18"
        />
        Write Your Essay
      </h4>
      <div class="word-count"
           :class="{ 'text-danger': isWordCountTooLow }"
      >
        <feather-icon
          :icon="isWordCountTooLow ? 'AlertCircle' : 'FileText'"
          size="16"
          class="mr-1"
        />
        {{ wordCount }} words
        <small v-if="isWordCountTooLow">(minimum {{ MINIMUM_WORD_COUNT  }} words recommended)</small>
      </div>
    </div>

    <div class="essay-content mb-3">
      <b-form-textarea
        v-model="essayContent"
        placeholder="Start writing your essay here... Be creative and express your thoughts clearly."
        rows="12"
        max-rows="20"
        :state="contentValidState"
        class="essay-textarea"
        @input="handleInput"
        @focus="handleFocus"
      />
      <div class="character-limit text-muted">
        <feather-icon icon="Type"
                      size="14"
                      class="mr-1"
        />
        {{ characterCount }}/2000 characters
      </div>
    </div>

    <div class="essay-controls d-flex justify-content-between align-items-center">
      <div class="controls-left">
        <b-button
          variant="outline-secondary"
          :disabled="!essayContent || isProcessing"
          class="d-flex align-items-center mr-2"
          @click="confirmClear"
        >
          <feather-icon icon="TrashIcon"
                        size="16"
                        class="mr-1"
          />
          Clear
        </b-button>
      </div>

      <div class="controls-right">
        <b-button
          variant="primary"
          :disabled="isProcessing || !canSubmit"
          class="submit-btn d-flex align-items-center"
          @click="submitEssay"
        >
          <b-spinner v-if="isProcessing"
                     small
                     class="mr-1"
          />
          <feather-icon
            v-else
            icon="Send"
            size="16"
            class="mr-1"
          />
          <span>{{ isProcessing ? 'Submitting...' : 'Submit Essay' }}</span>
        </b-button>
      </div>
    </div>

    <!-- Confirmation Modal -->
    <b-modal
      v-model="showClearConfirm"
      title="Clear Essay"
      ok-variant="danger"
      ok-title="Clear"
      @ok="clearEssay"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          icon="AlertTriangle"
          class="mr-2 text-warning"
          size="24"
        />
        <p class="mb-0">
          Are you sure you want to clear your essay? This action cannot be undone.
        </p>
      </div>
    </b-modal>
  </div>
</template>

<script setup>
import { ref, computed, getCurrentInstance } from 'vue'
import {
  BButton,
  BFormTextarea,
  BSpinner,
  BModal,
  // VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'

const MINIMUM_WORD_COUNT = 10
const root = getCurrentInstance().proxy.$root

const essayContent = ref('')
const isProcessing = ref(false)
const showClearConfirm = ref(false)
const hasUserStartedTyping = ref(false)

// Computed properties
const wordCount = computed(() => essayContent.value.trim().split(/\s+/).filter(word => word.length > 0).length)

const characterCount = computed(() => essayContent.value.length)

const isWordCountTooLow = computed(() => hasUserStartedTyping.value && wordCount.value < MINIMUM_WORD_COUNT)

const contentValidState = computed(() => {
  if (!hasUserStartedTyping.value) return null
  return wordCount.value >= 100
})

const canSubmit = computed(() => wordCount.value >= MINIMUM_WORD_COUNT && characterCount.value > 0 && characterCount.value <= 2000)

// Methods
const handleInput = () => {
  hasUserStartedTyping.value = true
}

const handleFocus = () => {
  hasUserStartedTyping.value = true
}

const confirmClear = () => {
  showClearConfirm.value = true
}

const submitEssay = async () => {
  if (!canSubmit.value) return

  isProcessing.value = true
  const standardId = root.$store.state.reaStore.activeStandard
  const mainPoints = root.$store.state.reaStore.activeMainPoints

  try {
    const response = await useJwt.saveStudentBotEssayHistory({
      standardId,
      content: essayContent.value,
      mainPoints,
    })
    root.showSuccessMessage(response)
    essayContent.value = ''
    hasUserStartedTyping.value = false
  } catch (error) {
    root.showErrorMessage(error)
  } finally {
    isProcessing.value = false
  }
}

const clearEssay = () => {
  essayContent.value = ''
  hasUserStartedTyping.value = false
  showClearConfirm.value = false
}
</script>

<style scoped>
.essay-writer {
  padding: 1.5rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.essay-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.essay-title {
  margin: 0;
  color: #2c3e50;
}

.word-count {
  font-size: 0.9rem;
  color: #6c757d;
  display: flex;
  align-items: center;
}

.essay-content {
  position: relative;
}

.essay-textarea {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 1rem;
  line-height: 1.6;
  transition: border-color 0.2s ease;
}

.essay-textarea:focus {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.character-limit {
  position: absolute;
  bottom: -20px;
  right: 0;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
}

.submit-btn {
  min-width: 120px;
}

.submit-btn:disabled {
  cursor: not-allowed;
}

/* Button icon alignment */

/* Transition animations */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
