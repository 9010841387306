<template>
  <BOverlay :show="isProcessing">
    <b-tabs v-model="activeVersion"
            content-class="mt-3"
    >
      <b-tab
        v-for="(tab, index) in essayVersions"
        :key="index"

        :title="`Version ${index + 1}`"
      >
        <div class="tab-content">
          <EssayPreview
            :user-message="tab.find(i => i.role=='user')?.content"
            :assistant-message="tab.find(i => i.role=='assistant')?.content"
          >
            <slot />
          </EssayPreview>
          <!-- Content for {{ tab.title }} -->
        </div>
      </b-tab>
    </b-tabs>
  </BOverlay>
</template>
<script setup>
import {
  ref, onMounted, getCurrentInstance, watch,
} from 'vue'
import {
  BTabs, BTab, BOverlay,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import EssayPreview from './components/EssayPreview.vue'

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
})

const activeVersion = ref(0)
const isProcessing = ref(false)
const essayVersions = ref([])
const root = getCurrentInstance().proxy.$root

watch(activeVersion, newVersion => {
  if (essayVersions.value[newVersion]) {
    root.$store.dispatch('reaStore/setEvaluationHistory', essayVersions.value[newVersion].map(i => ({ role: i.role, content: i.content })))
  }
})

onMounted(() => {
  const standardId = root.$store.state.reaStore.activeStandard
  isProcessing.value = true
  useJwt.getReaContextEvaluation({
    params: {
      type: props.type,
      standardId,
    },
  }).then(response => {
    const data = response.data.data.content
    essayVersions.value = data
    const tab = activeVersion.value >= 0 ? activeVersion.value : 0
    if (data[tab]) {
      root.$store.dispatch('reaStore/setEvaluationHistory', data[tab].map(i => ({ role: i.role, content: i.content })))
    }
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
})

</script>
